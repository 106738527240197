.loader{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background-color: white;
}

.loader__brand{
    width: 30rem;
    height: 30rem;
    object-fit: contain;
}

.loader__bar{
    width: 20rem;
    height: 0.75rem;
    border-radius: 10px;
    background-color: var(--color-secundary);
    margin-left: 2.5rem;
}

.loader__subBar{
    width: 0%;
    height: 100%;
    border-radius: 10px;
    background-color: var(--color-text);
    animation: charge 2.5s forwards;
}

@keyframes charge {
    0%{
        width: 0%;
    }100%{
        width: 100%;
    }
}