.navbar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: var(--color-text);
    height: auto;
    position: fixed;
    width: 100%;
    z-index: 99999999;
    padding: 1rem;
    box-sizing: border-box;
    border-bottom: 1px solid white;
}

.navbar__list-screen {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    font-size: 2rem;
}

.navbar__list-screen li {
    color: #141414;
    text-decoration: none;
    cursor: pointer;
    transition: 0.2s;
    list-style: none;
    color: white;
    font-weight: bold;
}

.navbar__list-screen li:hover {
    color: var(--color-secundary);
}

.navbar__logo {
    width: 7rem;
    height: 7rem;
    background-color: white;
    border-radius: 100%;
    object-fit: cover;
}

.navbar__btn {
    background-color: transparent;
    cursor: pointer;
    display: none;
    border-color: var(--color-text2);
    border-width: 1px;
}

.navbar__closedImg {
    width: 3rem;
    background-color: transparent;
}



@media screen and (max-width: 768px) {
    .navbar {
        gap: 0 1rem;
    }

    .navbar__list-screen {
        display: none;
        flex-direction: column;
        position: absolute;
        background-color: var(--color-text);
        left: 0;
        top: 70%;
        width: 100vw;
        padding: 1rem 0;
        animation: open 0.1s ease;
        gap: 1rem 0;
    }

    .navbar__btn {
        display: flex;
    }
}

@keyframes open {
    0% {
        height: 0;
    }

    100% {
        height: 10rem;
    }
}