.products {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    width: 100vw;
    min-height: 100vh;
    height: auto;
    padding: 10rem 0 2rem 0;
    gap: 5rem 0;
    position: relative;
    background-color: white;
}

.products h1 {
    color: var(--color-text);
    text-transform: uppercase;
    font-size: 5rem;
    margin: 1rem 0;
    text-align: center;
}

.carousel {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    background-color: transparent;
    overflow: auto;
    flex-wrap: wrap;
    gap: 2rem;
    padding: 10rem 1rem;
    box-sizing: border-box;
}

.carousel::-webkit-scrollbar {
    width: 6px;
    display: flex !important;
}

.carousel::-webkit-scrollbar-thumb:hover {
    background-color: var(--color-text);
}

.carousel__card {
    border: 2px solid var(--color-text);
    transition: 0.2s linear;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 30rem;
    height: 40rem;
    position: relative;
    cursor: pointer;
}

.carousel__card h2 {
    text-align: center;
    background-color: var(--color-text2);
    margin: 0;
    color: white;
    font-size: 2rem;
    padding: 0.5rem;
    box-sizing: border-box;
    width: 100%;
    height: auto;
    position: absolute;
    bottom: 0;
    text-transform: uppercase;
}

.carousel__img {
    width: 25rem;
    height: 25rem;
    box-sizing: border-box;
    object-fit: contain;
    padding: 1rem;
    box-sizing: border-box;
}

.carousel__card:hover {
    transform: scale(1.05);
}


.gallery {
    position: absolute;
    z-index: 1000;
}

.products__selected {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    box-sizing: border-box;
    gap: 4rem 0;
    width: 100%;
}

.products__selectedContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.products__selectedContainer button {
    position: absolute;
    right: 2%;
    font-size: 2rem;
    text-transform: uppercase;
    color: var(--color-text);
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    transition: 0.2s;
    z-index: 999999999;
}

.products__selectedContainer button:hover {
    color: var(--color-text2);
}


.image-gallery-image {
    width: 100%;
    height: 50rem;
}

.image-gallery-thumbnail-image {
    height: 10rem;
    object-fit: cover;
}

.image-gallery {
    width: 90%;
}

.image-gallery-thumbnail {
    cursor: pointer;
}

.image-gallery-thumbnail:hover{
    border-color: var(--color-text);
}

.image-gallery-icon:hover {
    color: var(--color-text);
}

@media screen and (max-width: 1024px) {
    .image-gallery-image{
        height: 45rem;
    }
}

@media screen and (max-width: 768px) {
    .products h1 {
        font-size: 3rem;
    }

    .products button {
        font-size: 1.5rem;
    }

    .products {
        background-image: none
    }

    .carousel {
        padding: 1rem 0;
    }
}

@media screen and (max-width: 520px) {
    .products__selectedContainer button {
        top: 18%;
    }
}

@media screen and (max-width: 375px) {
    .products__selectedContainer button {
        top: 21%;
    }
}