.about {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    min-height: 100vh;
    height: auto;
    background-color: white;
    padding: 4rem 1rem 5rem 0;
    box-sizing: border-box;
}

.about__container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    color: var(--color-secundary);
    gap: 2rem;
    width: 100%;
    flex-wrap: wrap;
}

.about__containerIMG {
    width: 50rem;
    height: 50rem;
    background-color: white;
    border-radius: 100%;
    object-fit: cover;
    border: 5px solid var(--color-text);
}

.aboutMe__text {
    width: 70rem;
    padding: 1rem 1rem;
    box-sizing: border-box;
}

.aboutMe__text h1 {
    text-transform: uppercase;
    color: var(--color-text);
    font-size: 6rem;
}

.aboutMe__text h2 {
    text-transform: uppercase;
    font-size: 4rem;
    color: var(--color-text2);
}

.aboutMe__text p {
    font-size: 2.5rem;
    font-weight: 400;
    color: var(--color-primary);
}

.aboutMe__text ul{
    font-size: 2.5rem;
}

.about__costumers {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    height: auto;
    gap: 2rem 0;
    padding: 2rem 0;
    box-sizing: border-box;
}

.about__marquee {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: auto;
    background-color: white;
    padding: 1rem 0;
    box-sizing: border-box;
}

.about__costumers h2 {
    font-size: 5rem;
    color: var(--color-text);
    margin: 0.25rem 0;
}

.marquee__logo {
    min-width: 5rem;
    height: 7rem;
    background-color: transparent;
    margin: 0 5rem;
    filter: grayscale();
}

@media screen and (max-width: 1239px) {
    .about {
        padding: 10rem 0 2rem 0;
    }

}

@media screen and (max-width: 768px) {

    .about__containerIMG {
        width: 30rem;
        height: 30rem;
    }

    .aboutMe__text h1,
    .about__costumers h2 {
        font-size: 4rem;
    }

    .aboutMe__text h2{
        font-size: 3rem;
    }

    .aboutMe__text p, .aboutMe__text ul  {
        font-size: 2.5rem;
    }

}

@media screen and (max-width: 375px) {

    .about__containerIMG {
        width: 20rem;
        height: 20rem;
    }

    .aboutMe__text h1,
    .about__costumers h2 {
        font-size: 3rem;
    }

    .aboutMe__text h2 {
        font-size: 2rem;
    }

    .aboutMe__text p, .aboutMe__text ul  {
        font-size: 1.75rem;
    }
}