:root {
  --color-primary: #0F0E0E;
  --color-secundary: #212121;
  --color-text: #809D3C;
  --color-text2:#92A072;
}

html {
  font-size: 62.5%;
  font-family: 'Economica', sans-serif;
  overflow-x: hidden;
}

body {
  margin: 0;
  padding: 0;
  background-color: white;
}

::-webkit-scrollbar {
  /* display: none; */
  width: 8px;
}

::-webkit-scrollbar-track {
  background: white;
}


::-webkit-scrollbar-thumb {
  background: var(--color-text2);
}


::-webkit-scrollbar-thumb:hover {
  background: var(--color-secundary);
}


button{
  font-family: 'Economica', sans-serif !important;
}