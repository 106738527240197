.contact {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: auto;
    min-height: 100vh;
    background-color: white;
    background-image: url(../../images/background/hoja.png);
    background-repeat: no-repeat;
    background-position: 100% 100%; 
    background-size: 40%;
    padding-top: 2.5rem;
}

