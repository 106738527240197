@import "~react-image-gallery/styles/css/image-gallery.css";

.App {
    overflow-x: hidden;
}


.whatsapp{
    width: 5.5rem;
    height: 5.5rem;
    transition: 0.2s;
    cursor: pointer;
    position: fixed;
    bottom: 14rem; 
    right: 2rem; 
    z-index: 999;
    opacity: 0.2;
}

.whatsapp:hover{
    opacity: 1;
}