.footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: var(--color-secundary);
    width: 100vw;
    height: auto;
    padding: 2rem;
    box-sizing: border-box;
    gap: 5rem 1rem;
}


.footer__logo{
    width: 12rem;
    background-color: white;
    padding: 0.75rem 1rem;
    box-sizing: border-box;
    border-radius: 10px;
}

.footer__socials{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0 2rem;
}


.footer__socials h2{
    color: var(--color-text);
    margin: 0 1rem 0 0;
    font-size: 2.5rem;
}

.footer__socials span{
    color: var(--color-text);
    font-size: 2.5rem;
}

.footer__icons{
    width: 3rem;
    height: 3rem;
    cursor: pointer;
    transition: 0.2s;
}

.footer__icons:hover{
    transform: scale(1.2);
}

.footer__contact{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    gap: 1rem 0;
    font-size: 1.25rem;
}

.footer__contact h3, .footer__contact h2{
    margin: 0;
}

.footer__contact a{
    text-decoration: underline;
    color: var(--color-text);
    transition: 0.2s;
}

.footer__contact a:hover{
    color: var(--color-text2);
}

.footer__contact span{
    color: var(--color-text);
}


@media screen and (max-width: 768px) {
    .footer{
        flex-direction: column;
    }

    .footer__contact{
        text-align: center;
    }

    .footer__logo{
        width: 10rem;
    }
}

@media screen and (max-width: 425px) {
    .footer__icons{
        width: 2.5rem;
        height: 2.5rem;
    }
    
}

